import React from 'react';

import subMenuStyles from '#/general/header/subMenu.module.scss';
import { TopMenuSectionColumnItems } from '~/global/top-menu/TopMenuSectionColumnItems';

export const TopMenuSectionColumns = (props) => {
	const {
		subMenuColumns = [],
	} = props;

	return (
		<div className={subMenuStyles['subMenuNavigationLinks']}>
			{
				subMenuColumns.map(({ columnKey, columnMenus }) => {
					return <div className={`${subMenuStyles['menuColl']}`} key={columnKey} data-column-key={columnKey} data-qa="topMenu-megaMenu">
						{
							columnMenus.map((collMenu) => {
								const {
									title: colMenuTitle,
									classes: colMenuClasses,
									subMenuItems: colMenuChildren,
									// promoUiKey: colMenuPromoUiKey,
									url
								} = collMenu;

								return (
									<section className={`${subMenuStyles['menu-node']} ${colMenuClasses}`} key={collMenu.id}>
										{colMenuTitle &&
											<div>
												{!url &&
													<span
														role="heading"
														aria-level="2"
														className={subMenuStyles['menu-node-heading']}
														dangerouslySetInnerHTML={{ __html: colMenuTitle }}
													/>
												}
												{url && (
													<a
														className={`${subMenuStyles['menu-node-heading']} ${subMenuStyles['menu-node-heading-link']}`}
														href={url}
													>
														<span
															role="heading"
															aria-level="2"
															dangerouslySetInnerHTML={{ __html: colMenuTitle }}
														/>
													</a>
												)}
											</div>
										}
										<ul>
											<TopMenuSectionColumnItems colMenuItems={colMenuChildren} />
										</ul>
									</section>
								);
							})
						}
					</div>;
				})
			}
		</div>
	);
};
