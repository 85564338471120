'use client';

import React, { useEffect, useRef } from 'react';

import type { SiteMarketingMessage as ISiteMarketingMessage } from '~/site-marketing-messaging/Models/SiteMarketingMessage.model';

import styles from '~/site-marketing-messaging/Components/SiteMarketingMessage.module.scss';

export const SiteMarketingMessage = ({
	siteMarketingMessage: {
		message,
		name,
		startDate,
	},
	siteMarketingMessagesCount,
	siteMarketingMessageIndex,
}: {
	siteMarketingMessage: ISiteMarketingMessage,
	siteMarketingMessagesCount: number,
	siteMarketingMessageIndex: number,
}) => {
	const siteMarketingMessageRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const linkElements = siteMarketingMessageRef?.current?.querySelectorAll('a');

		linkElements?.forEach((linkElement) => {
			linkElement.setAttribute('data-tr-link-event-name', siteMarketingMessageRef?.current?.innerText || '');
		});
	}, [siteMarketingMessageRef.current]);

	return (
		<div
			className={styles['site-marketing-message']}
			dangerouslySetInnerHTML={{ __html: message }}
			data-qa="site-marketing-message"
			data-tr-link-event-comp-name={`${name} ${startDate}`}
			data-tr-link-event-comp-position={`${siteMarketingMessageIndex + 1}:${siteMarketingMessagesCount}`}
			ref={siteMarketingMessageRef}
		/>
	);
};
