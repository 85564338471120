'use client';

import Cookie from 'js-cookie';
import { observer } from 'mobx-react';
import { useEffect } from 'react';

import type { Monetate } from '~/monetate/Models/Monetate.model';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { DISPLAY_TYPE } from '~/monetate/Types/Monetate.type';
import { monetateModalSettings } from '~/monetate/modal/monetate-modal.constants';

export const MonetateModal = observer(() => {
	const {
		magicModal = {},
		monetateAPIModel = {},
		monetateAPIStore = {},
	} = useGlobalContext();

	const { monetateActionModelsByDisplayType = {} }: Monetate = monetateAPIModel;

	const monetateActionModalModels = monetateActionModelsByDisplayType[DISPLAY_TYPE.MODAL];

	const [monetateActionModalModel = {}] = monetateActionModalModels || [];

	const {
		impressionId = '',
		json: {
			apiEventKey = '',
			component = 'DEFAULT',
			timer = 0,
			type = '',
		} = {},
	} = monetateActionModalModel || {};

	function recordPageEvent() {
		if (apiEventKey) {
			monetateAPIStore.recordPageEvents([apiEventKey]);
		}
	}

	function openMonetateModal() {
		const modalTypeSettings = monetateModalSettings[type as keyof typeof monetateModalSettings];

		if (!modalTypeSettings) {
			if (type !== '') {
				console.warn(`No modalTypeSettings found for modal type ${type}`);
			}

			return;
		}

		const isMediaQueryMd = window.matchMedia('(min-width: 40em)').matches;

		const modalProps = {
			clickHandler: recordPageEvent,
			isMediaQueryMd,
		};

		const modalSettings: any = modalTypeSettings[component as keyof typeof modalTypeSettings];

		const modalSettingsWithProps = modalSettings ? modalSettings({
			isMediaQueryMd,
			modalProps,
		}) : {};

		setTimeout(() => {
			magicModal.openModal({
				containerClass: 'monetate-modal',
				onCloseModal: () => {
					magicModal.alterModal({
						containerClass: '',
					});
				},
				...modalSettingsWithProps,
			});

			monetateAPIStore.recordImpressions([impressionId]);

			Cookie.set('rnb.MONETATE_MODAL', type);
		}, timer);
	}

	useEffect(() => {
		// need to figure out how to send cookies to Monetate API, rather than cherry picking here
		const outdoorGiveawayModalCookie = Cookie.get('rnb.OUTDOOR_GIVEAWAY_ENTERED');

		if (outdoorGiveawayModalCookie && type === 'OUTDOOR_GIVEAWAY') {
			return;
		}

		const monetateModalCookie = Cookie.get('rnb.MONETATE_MODAL');

		if (!monetateActionModalModel || monetateModalCookie) {
			return;
		}
		if (!magicModal.model.isOpen) {
			openMonetateModal();
			return;
		}
		if (typeof magicModal.model.onCloseModal !== 'function') {
			magicModal.model.onCloseModal = openMonetateModal;
			return;
		}
		const existingCloseModalCallback = magicModal.model.onCloseModal;

		magicModal.model.onCloseModal = () => {
			existingCloseModalCallback();
			openMonetateModal();
		};
	}, [impressionId]);

	return null;
});
