import React from 'react';

import { dynaSitePath } from '~/global/global.constants';
import { MapPinSvg } from '~/account/top-links/Components/MapPin.svg';

export const StoresLink = () => {
	return (
		<a
			href={`${dynaSitePath}/stores`}
			className="tw-hidden-for-print top-link stores-link"
			data-tr-link-event-name="stores icon"
			title="Stores"
		>
			<MapPinSvg hoverFill />
			<span className="tw-sr-only">Store locations</span>
		</a>
	);
}
