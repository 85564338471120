import React from 'react';

import { FDSModalWrapper } from '~/components/magic-modal/Components/FDSModal';
import { OutdoorGiveawayModalWrapper } from '~/components/magic-modal/Components/OutdoorGiveawayModal';
import { StorageGiveawayModalWrapper } from '~/components/magic-modal/Components/StorageGiveawayModal';
import { SwatchOrderingModalWrapper } from '~/components/magic-modal/Components/SwatchOrderingModal';
import { EmailSignUpModalNew } from '~/email-signup/Components/EmailSignUpModalNew';
import { EmailSignUpFormStoreFactory } from '~/email-signup/Stores/emailSignUpForm.store';
import { FreeDesignServicesModalDefault } from '~/free-design-services-modal/Components/freeDesignServicesModalDefault';
import { OutdoorGiveawayModalDefault } from '~/outdoor-giveaway-modal/Components/OutdoorGiveawayModalDefault';
import { StorageGiveawayModalDefault } from '~/storage-giveaway-modal/Components/StorageGiveawayModalDefault';
import { SwatchOrderingModalDefault } from '~/swatch-ordering/Components/SwatchOrderingModalDefault';
import { noop } from '~/util/noop';

const getEmailSignUpModalStore = ({
	clickHandler = noop,
	isMediaQueryMd = false,
}) => {
	return EmailSignUpFormStoreFactory.create({
		emailSignUpFormSuccessCallback: clickHandler,
		hideLegend: true,
		locationId: 'DEFAULT',
		magicSpinnerProps: {
			isLoading: true,
			minHeight: '150px',
		},
		source: !isMediaQueryMd ? 'PROACTIVE_MOBILE' : 'PROACTIVE_DESKTOP',
		useInlineSubmitButton: !isMediaQueryMd,
	});
};

export const monetateModalSettings = {
	FREE_DESIGN_SERVICES: {
		DEFAULT: ({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			isMediaQueryMd = false,
			modalProps = {},
		}) => {
			return {
				reverseCloseButton: true,
				WrapperComponent: FDSModalWrapper,
				flushSides: true,
				content: {
					children: <FreeDesignServicesModalDefault {...modalProps} />,
				},
				title: false,
				width: '90vw',
			};
		},
	},
	OUTDOOR_GIVEAWAY: {
		DEFAULT: ({
			modalProps = {},
		}) => {
			return {
				reverseCloseButton: false,
				WrapperComponent: OutdoorGiveawayModalWrapper,
				flushSides: true,
				content: {
					children: <OutdoorGiveawayModalDefault {...modalProps} />,
				},
				title: false,
				width: '90vw',
				maxWidth: 660,
			};
		},
	},
	STORAGE_GIVEAWAY: {
		DEFAULT: ({
			modalProps = {},
		}) => {
			return {
				reverseCloseButton: false,
				WrapperComponent: StorageGiveawayModalWrapper,
				flushSides: true,
				content: {
					children: <StorageGiveawayModalDefault {...modalProps} />,
				},
				title: false,
				width: '90vw',
				maxWidth: 660,
			};
		},
	},
	PROACTIVE_EMAIL_SIGNUP: {
		FIRST_TO_KNOW: ({
			isMediaQueryMd = false,
			modalProps = {},
		}) => {
			const { clickHandler } = modalProps;

			const emailSignUpFormStore = getEmailSignUpModalStore({
				clickHandler,
				isMediaQueryMd,
			});

			const additionalModalProps = {
				emailSignUpFormStore,
				headingText: 'Be the first to know!',
				imageName: 'novato_email_signup_modal',
				imageWidth: 270,
				isNoImageEmailSignUpModalContent: !isMediaQueryMd,
				subheadingText: 'Subscribers are the first to hear about new products and clearance events.',
			};

			const combinedModalProps = {
				...modalProps,
				...additionalModalProps,
			};

			return {
				anchorBottom: !isMediaQueryMd,
				content: {
					children: (
						<EmailSignUpModalNew {...combinedModalProps} />
					),
				},
				dropShadow: !isMediaQueryMd,
				fullBleed: true,
				reverseCloseButton: !isMediaQueryMd,
				showOverlay: isMediaQueryMd,
				title: '',
				width: isMediaQueryMd ? 660 : '95vw',
			};
		},
		FIRST_TO_KNOW_NO_IMAGE: ({
			isMediaQueryMd = false,
			modalProps = {},
		}) => {
			const { clickHandler } = modalProps;

			const emailSignUpFormStore = getEmailSignUpModalStore({
				clickHandler,
				isMediaQueryMd,
			});

			const additionalModalProps = {
				emailSignUpFormStore,
				headingText: 'Be the first to know!',
				imageWidth: 0,
				isNoImageEmailSignUpModalContent: true,
				subheadingText: 'Subscribers are the first to hear about new products and clearance events.',
			};

			const combinedModalProps = {
				...modalProps,
				...additionalModalProps,
			};

			return {
				anchorBottom: !isMediaQueryMd,
				content: {
					children: (
						<EmailSignUpModalNew {...combinedModalProps} />
					),
				},
				dropShadow: !isMediaQueryMd,
				fullBleed: true,
				reverseCloseButton: true,
				showOverlay: isMediaQueryMd,
				title: '',
				width: isMediaQueryMd ? 445 : '95vw',
			};
		},
		MOST_BEAUTIFUL_EMAILS: ({
			isMediaQueryMd = false,
			modalProps = {},
		}) => {
			const { clickHandler } = modalProps;

			const emailSignUpFormStore = getEmailSignUpModalStore({
				clickHandler,
				isMediaQueryMd,
			});

			const additionalModalProps = {
				emailSignUpFormStore,
				headingText: 'The most beautiful emails in your inbox.',
				imageName: 'callan_email_signup_modal',
				imageWidth: 270,
				isNoImageEmailSignUpModalContent: !isMediaQueryMd,
				subheadingText: 'Modern design inspiration and ideas, right at your fingertips.',
			};

			const combinedModalProps = {
				...modalProps,
				...additionalModalProps,
			};

			return {
				anchorBottom: !isMediaQueryMd,
				content: {
					children: (
						<EmailSignUpModalNew {...combinedModalProps} />
					),
				},
				dropShadow: !isMediaQueryMd,
				fullBleed: true,
				reverseCloseButton: !isMediaQueryMd,
				showOverlay: isMediaQueryMd,
				title: '',
				width: isMediaQueryMd ? 660 : '95vw',
			};
		},
		SLIM: ({
			isMediaQueryMd = false,
			modalProps = {},
		}) => {
			const { clickHandler } = modalProps;

			const emailSignUpFormStore = getEmailSignUpModalStore({
				clickHandler,
				isMediaQueryMd,
			});

			const additionalModalProps = {
				emailSignUpFormStore,
				headingText: 'So much more to see!',
				imageWidth: 0,
				isNoImageEmailSignUpModalContent: true,
				subheadingText: 'Sign up for emails to be the first to know about new products and clearance events.',
			};

			const combinedModalProps = {
				...modalProps,
				...additionalModalProps,
			};

			return {
				anchorBottom: !isMediaQueryMd,
				content: {
					children: (
						<EmailSignUpModalNew {...combinedModalProps} />
					),
				},
				dropShadow: !isMediaQueryMd,
				fullBleed: true,
				reverseCloseButton: true,
				showOverlay: isMediaQueryMd,
				title: '',
				width: isMediaQueryMd ? 445 : '95vw',
			};
		},
	},
	SWATCH_ORDERING: {
		DEFAULT: ({
			// eslint-disable-next-line @typescript-eslint/no-unused-vars
			isMediaQueryMd = false,
			modalProps = {},
		}) => {
			return {
				reverseCloseButton: true,
				WrapperComponent: SwatchOrderingModalWrapper,
				flushSides: true,
				content: {
					children: <SwatchOrderingModalDefault {...modalProps} />,
				},
				title: false,
				width: '90vw',
				maxWidth: 640,
			};
		},
	},
};
