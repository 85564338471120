import React from 'react';

export const HeaderLogo = () => {
	return (
		<div id="logo">
			<a href="/" data-tr-link-event-name="roomandboard" data-tr-link-event-comp-name="logo image">
				<img src="/img/header/main_logo_black.svg" alt="Room & Board - Homepage." />
			</a>
			<div className="print-only here-to-help">
				We're here to help<br />800.301.9720
			</div>
		</div>
	);
};
