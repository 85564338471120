'use client';

import React, { useState, useEffect, useRef } from 'react';
import cn from 'classnames';

import { isOnServer } from '~/global/global.constants';
import { TopMenuSchema } from '~/global/top-menu/TopMenuSchema';
import styles from '#/general/header/topmenu.module.scss';
import { TopMenuSection } from '~/global/top-menu/TopMenuSection';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import { debounce } from '~/util/debounce';

export const TopMenu = () => {
	const {
		featureTogglesModel,
		topMenus = [],
	} = useGlobalContext();
	let hoverDelay = 300;

	if (featureTogglesModel?.isOn?.('DISABLE_TOP_MENU_HOVER')) {
		hoverDelay = 9e5;
	}
	const [expandedMenuId, setExpandedMenuId] = useState(null);
	const [mouseOffTimeout, setMouseOffTimeout] = useState(0);

	const updateExpandedMenuIdRef = useRef(debounce((id, fromHover = false) => {
		if (!fromHover) {
			setExpandedMenuId(expandedMenuId === id ? null : id);
		} else if (expandedMenuId !== id || id === null) {
			setExpandedMenuId(id);
		}
	}, hoverDelay));

	useEffect(() => {
		if (!isOnServer) {
			// Make sure to close all menus opened by keyboard navigation when hitting esc (there should only be 1)
			document.addEventListener('keydown', (e) => {
				if (e.keyCode === 27) {
					updateExpandedMenuIdRef.current(null);
				}
			});

			document.addEventListener('click', (e) => {
				if (!expandedMenuId && !e.target.closest('#navigati-nav')) {
					updateExpandedMenuIdRef.current(null);
				}
			});
		}
	}, []);

	const handleMouseover = () => {
		if (mouseOffTimeout) {
			window.clearTimeout(mouseOffTimeout);
		}
	};

	const handleMouseout = () => {
		if (mouseOffTimeout) {
			window.clearTimeout(mouseOffTimeout);
		}
		setMouseOffTimeout(setTimeout(() => {
			// Cancel any debounced calls
			updateExpandedMenuIdRef.current.cancel();
			setExpandedMenuId(null);
		}, 200));
	};

	const classNames = cn(`${styles['top-menu']} tw-hidden-for-print tw-hidden md:tw-block`, {
		[styles['menu-visible']]: expandedMenuId != null,
	});

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<nav
			id="navigati-nav"
			aria-label="Main menu"
			className={classNames}
			data-qa="topMenu"
			data-tr-link-event-comp-type="header navigation"
			onMouseEnter={handleMouseover}
			onMouseLeave={handleMouseout}
		>
			<ul>
				{
					topMenus.map((menu) => {
						const {
							id,
						} = menu;

						return (
							<TopMenuSection
								key={`top-menu-section-${id}`}
								menu={menu}
								isExpanded={id === expandedMenuId}
								expandedMenuId={expandedMenuId}
								setExpandedMenuId={updateExpandedMenuIdRef.current}
							/>
						);
					})
				}
			</ul>
			<TopMenuSchema menuData={topMenus} />
		</nav>
	);
};
