import { AutoPlay, Fade } from '@egjs/flicking-plugins';
import Flicking from '@egjs/react-flicking';
import React from 'react';

import type {
	HoldEndEvent, Panel, ReadyEvent, VisibleChangeEvent, WillChangeEvent
} from '@egjs/react-flicking';
import type { RefObject } from 'react';

import { useGlobalContext } from '~/global/Contexts/Global.context';
import { SiteMarketingMessage } from '~/site-marketing-messaging/Components/SiteMarketingMessage';
import { DIRECTION, INPUT_TYPE, MOVE_TYPE } from '~/site-marketing-messaging/SiteMarketingMessaging.constants';
import { LinkEventTypes } from '~/tracking/link-event/Models/LinkEvent.model';
import { without } from '~/util/without';

import type { SiteMarketingMessaging } from '~/site-marketing-messaging/Models/SiteMarketingMessaging.model';

import styles from '~/site-marketing-messaging/Components/SiteMarketingMessages.module.scss';

export const SiteMarketingMessages = ({
	flickingRef,
	siteMarketingMessagingModel,
	siteMarketingMessagingModel: {
		activeMessageIndex,
		siteMarketingMessages,
		siteMarketingMessagesCount,
	},
}: {
	flickingRef: RefObject<Flicking>,
	siteMarketingMessagingModel: SiteMarketingMessaging,
}) => {
	const { linkEventStore } = useGlobalContext();

	const autoPlayPlugin = new AutoPlay({ duration: 4000 });

	const fadePlugin = new Fade();

	function handleFocusIn() {
		autoPlayPlugin.stop();
	}

	function handleFocusOut() {
		const { paused } = siteMarketingMessagingModel;

		if (!paused) {
			autoPlayPlugin.play();
		}
	}

	function updatePanel({
		isVisible,
		panel: {
			element,
		},
	}: {
		isVisible: boolean,
		panel: Panel,
	}) {
		const focusableElements = element.querySelectorAll('a');

		if (isVisible) {
			element.removeAttribute('aria-hidden');
			element.addEventListener('focusin', handleFocusIn);
			element.addEventListener('focusout', handleFocusOut);
		} else {
			element.setAttribute('aria-hidden', 'true');
			element.removeEventListener('focusin', handleFocusIn);
			element.removeEventListener('focusout', handleFocusOut);
		}

		focusableElements.forEach((focusableElement: HTMLAnchorElement) => {
			if (isVisible) {
				focusableElement.removeAttribute('tabindex');
			} else {
				focusableElement.setAttribute('tabindex', '-1');
			}
		});
	}

	function updatePanels({
		panels,
		visiblePanels,
	}: {
		panels: Panel[],
		visiblePanels: Panel[],
	}) {
		const invisiblePanels = without(panels, ...visiblePanels);

		invisiblePanels.forEach((panel) => {
			updatePanel({
				isVisible: false,
				panel,
			});
		});

		visiblePanels.forEach((panel) => {
			updatePanel({
				isVisible: true,
				panel,
			});
		});
	}

	function handleHoldEnd({ currentTarget }: HoldEndEvent) {
		currentTarget.once('changed', ({ direction }) => {
			const linkEventTrackingData = {
				trLinkEventCompName: 'general functionality',
				trLinkEventCompType: 'sitewide banner',
				trLinkEventName: `Navigate to ${direction === DIRECTION.NEXT ? 'next' : 'previous'} message`,
				trLinkEventType: (LinkEventTypes as any).SITE_ACTION,
			};

			linkEventStore?.trackLinkEvent(linkEventTrackingData);

			autoPlayPlugin.stop();

			siteMarketingMessagingModel.paused = true;
		});
	}

	function handleReady({
		currentTarget: {
			panels,
			visiblePanels,
		},
	}: ReadyEvent<Flicking>) {
		const { paused } = siteMarketingMessagingModel;

		if (paused) {
			autoPlayPlugin.stop();
		}

		updatePanels({
			panels,
			visiblePanels
		});
	}

	function handleVisibleChange({
		currentTarget: {
			panels,
		},
		visiblePanels,
	}: VisibleChangeEvent<Flicking>) {
		updatePanels({
			panels,
			visiblePanels,
		});
	}

	function handleWillChange({
		panel: {
			index,
		},
	}: WillChangeEvent<Flicking>) {
		siteMarketingMessagingModel.activeMessageIndex = index;
	}

	return (
		<Flicking
			circular
			className={styles['site-marketing-messages']}
			defaultIndex={activeMessageIndex}
			hideBeforeInit
			inputType={[INPUT_TYPE.TOUCH]}
			moveType={[MOVE_TYPE.STRICT]}
			onHoldEnd={handleHoldEnd}
			onReady={handleReady}
			onVisibleChange={handleVisibleChange}
			onWillChange={handleWillChange}
			panelsPerView={1}
			plugins={[
				autoPlayPlugin,
				fadePlugin,
			]}
			ref={flickingRef}
		>
			{
				siteMarketingMessages.map((siteMarketingMessage, index) => {
					return (
						<div key={`site-marketing-message-${index}`}>
							<SiteMarketingMessage
								siteMarketingMessage={siteMarketingMessage}
								siteMarketingMessageIndex={index}
								siteMarketingMessagesCount={siteMarketingMessagesCount}
							/>
						</div>
					);
				})
			}
		</Flicking>
	);
};
