import React from 'react';

export const CartLinkPlaceholder = () => {
	return (
		<a
			href="/cart"
			className="tw-block tw-relative tw-leading-[0] tw-h-5 md:tw-w-[34px] md:tw-h-[27px] top-link"
			data-tr-link-event-comp-name="top links"
			data-tr-link-event-name="cart icon"
			data-qa="cart-items-status-link"
			data-track
			title="Cart"
		>
			<svg
				className="tw-h-5 md:tw-h-[27px]"
				xmlns="http://www.w3.org/2000/svg"
				width="30.855"
				height="21.7"
				viewBox="0 0 30.855 21.7"
				focusable="false"
			>
				<g data-name="Group 39" transform="translate(-1209 -13.5)">
					<g transform="translate(1203.7 9.7)">
						<path
							data-name="Path 22"
							d="M5.3,4.3h7.1l3.7,14.2H32.6L35.5,7.9H15.7"
							fill="#fff"
							stroke="#333"
							strokeMiterlimit="10"
							strokeWidth="1"
						/>
						<circle
							cx="2"
							cy="2"
							r="2"
							transform="translate(15.8 21)"
							fill="#fff"
							stroke="#333"
							strokeMiterlimit="10"
							strokeWidth="1"
						/>
						<circle
							cx="2"
							cy="2"
							r="2"
							transform="translate(28.4 21)"
							fill="#fff"
							stroke="#333"
							strokeMiterlimit="10"
							strokeWidth="1"
						/>
					</g>
					<path
						className="hover-fill"
						d="M13336.25-190.375h21.859l-2.764,10.406h-16.332Z"
						transform="translate(-12119.109 207.969)"
						fill="#fff"
						stroke="#333"
						strokeWidth="1"
					/>
				</g>
			</svg>
			<span className="tw-sr-only">Cart</span>
		</a>
	);
};

// eslint-disable-next-line import/no-default-export
export default CartLinkPlaceholder;
