import React from 'react';

import { AccountSvg } from '~/account/top-links/Components/Account.svg';

export const AccountMenuPlaceholder = () => {
	return (
		<div
			className="account-menu legacy-top-menu tw-hidden-for-print"
		>
			<button
				className="top-link has-menu sign-in-status-link tw-text-gray"
				data-tr-link-event-name="sign in icon"
				data-qa="account-link-placeholder"
				title="Sign In"
				aria-controls="subMenu-account"
				aria-expanded={false}
				data-status-known={false}
				type="button"
			>
				<AccountSvg loggedIn={false} />
				<span className="tw-sr-only">
					Sign In
				</span>
			</button>
		</div>
	);
};

export default AccountMenuPlaceholder;
