import React, { useState } from 'react';
import cn from 'classnames';

import subMenuStyles from '#/general/header/subMenu.module.scss';
import styles from '#/general/header/topmenu.module.scss';
import { TopMenuSectionColumns } from '~/global/top-menu/TopMenuSectionColumns';
import { TopMenuSectionPromoItems } from '~/global/top-menu/TopMenuSectionPromoItems';

const isMenuHidden = menu => menu.hiddenOnFull;

export const TopMenuSection = (props) => {
	const {
		menu,
		menu: {
			id,
			url,
			title,
			classes,
			promoClasses,
			subMenuColumns,
			columnCount,
			contentPromos,
		} = {},
		isExpanded = false,
		expandedMenuId,
		setExpandedMenuId,
	} = props;

	if (isMenuHidden(menu)) {
		return null;
	}

	const [mouseOverTimeout, setMouseOverTimeout] = useState(0);

	const collCountClasses = {
		1: subMenuStyles['coll_1'], 2: subMenuStyles['coll_2'], 3: subMenuStyles['coll_3'], 4: subMenuStyles['coll_4']
	};

	const onMenuClick = () => {
		if (isExpanded) {
			setExpandedMenuId(null);
		} else {
			setExpandedMenuId(id);
		}
	};

	const onMenuFocus = (e) => {
		if (expandedMenuId !== e.currentTarget.getAttribute('data-menu-id')) {
			setExpandedMenuId(null);
		}
	};

	const onMenuTouchEnd = (e) => {
		const targetMenuId = e.currentTarget.getAttribute('data-menu-id');

		if (targetMenuId !== expandedMenuId) {
			e.preventDefault();
		}

		setExpandedMenuId(targetMenuId);
	};

	const handleMouseout = () => {
		if (mouseOverTimeout) {
			window.clearTimeout(mouseOverTimeout);
		}
	};

	const handleMouseover = () => {
		if (expandedMenuId !== null) {
			if (mouseOverTimeout) {
				window.clearTimeout(mouseOverTimeout);
			}
			setExpandedMenuId(id, true);
		} else {
			setMouseOverTimeout(setTimeout(() => {
				setExpandedMenuId(id, true);
			}, 270));
		}
	};

	const className = cn(classes, {
		[styles['showMenu']]: isExpanded,
	});

	return (
		// eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
		<li className={className} key={id + title} data-tr-link-event-comp-name={title} onMouseEnter={handleMouseover} onMouseLeave={handleMouseout}>
			<a
				href={url}
				onFocus={onMenuFocus}
				onTouchEnd={onMenuTouchEnd}
				data-menu-id={id}
				data-qa={`root-menu-${title}`}
			>
				<span dangerouslySetInnerHTML={{ __html: title }} />
			</a>

			<div className={styles['openMenuHolder']}>
				<button
					aria-controls={`subMenu-${id}`}
					onClick={onMenuClick}
					onFocus={onMenuFocus}
					aria-expanded={isExpanded}
					className={styles['openMenu']}
					data-menu-id={id}
					data-qa={`root-menu-open-button-${title}`}
				>
					<span className="tw-sr-only">{isExpanded ? 'Close' : 'Open'} {<span dangerouslySetInnerHTML={{ __html: title }} />} menu</span>
				</button>
			</div>
			<div
				className={cn(
					styles['subMenu'],
					subMenuStyles['subMenu'],
					collCountClasses[columnCount],
				)}
				id={`subMenu-${id}`}
				data-qa={`sub-menu-${title}`}
			>
				<div className={subMenuStyles['subMenu-max']}>
					<TopMenuSectionColumns subMenuColumns={subMenuColumns} />
					<TopMenuSectionPromoItems promoClasses={promoClasses} menuPromoItems={contentPromos} />
				</div>
			</div>
		</li>
	);
};
