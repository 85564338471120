import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import listStyles from '#/mobile/global/menuList.module.scss';

import { s7ContentPath } from '~/global/global.constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';

const TopMenuMobileSubmenuSecondary = observer((props) => {
	const { topMenuMobileStore } = useGlobalContext();

	const {
		menu: {
			subMenus = [],
			dataKey = '',
			isActive,
			title: menuTitle = '',
			url = '',
			title,
		},
	} = props;

	useEffect(() => {
		if (isActive) {
			window.scrollTo(0, 0);
		}
	});

	return (
		<div data-submenu-index={dataKey} data-qa={`mobile-submenu-${title}`} style={ { display: isActive ? 'block' : 'none' } }>
			<ul className={listStyles['menuList']}>
				<li data-nav-track="BACK">
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a
						className={listStyles['back-button']}
						data-mobile-submenu-back={title.toUpperCase()}
						data-tr-link-event-track={false}
						data-qa="mobile-nav-back-button"
						href="#"
						onClick={
							(event) => {
								event.preventDefault();

								topMenuMobileStore.deactivateMenus({ nextFocusSelector: `[data-mobile-submenu="${(title).toUpperCase()}"]` });
							}
						}
					>
						<span aria-hidden="true">
							<img src={`${s7ContentPath}/icon_backSecondarySubmenu_gray`} width="6" alt="" />
						</span>
						<span aria-label="Main Menu">Main Menu</span>
					</a>
				</li>
			</ul>
			{
				subMenus.map((submenu, submenuGroupIndex) => {
					const {
						subMenuItems = [],
					} = submenu;

					return (
						<React.Fragment key={`submenuGroup-${submenuGroupIndex}`}>
							<ul className={classNames(
								listStyles['menuList'],
								listStyles['link-list-icon']
							)}>
								<li className={listStyles[`link-item-${dataKey}`]} data-nav-track={title.toUpperCase()}>
									<a data-tr-link-event-comp-name={menuTitle} data-qa={`mobile-submenu-${title}-link-${title}`} href={url}>{title}</a>
								</li>
							</ul>
							<ul className={classNames(
								listStyles['menuList'],
								listStyles['link-list-secondary']
							)}>
								{
									subMenuItems.map((submenuLink, submenuLinkIndex) => {
										const {
											hiddenOnMobile,
											title: submenuLinkTitle = '',
											url: submenuLinkUrl = '',
										} = submenuLink;

										if (hiddenOnMobile) {
											return null;
										}

										return (
											<li data-nav-track={submenuLinkTitle.toUpperCase()} key={`submenuLink-${submenuLinkIndex}`}>
												<a
													data-tr-link-event-comp-name={menuTitle}
													data-qa={`mobile-submenu-${title}-link-${submenuLinkTitle}`}
													href={submenuLinkUrl}
												>{submenuLinkTitle}</a>
											</li>
										);
									})
								}
							</ul>
						</React.Fragment>
					);
				})
			}
		</div>
	);
});

export { TopMenuMobileSubmenuSecondary };
