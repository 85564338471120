import React from 'react';
import { observer } from 'mobx-react';

import { s7ContentPath } from '~/global/global.constants';
import { useGlobalContext } from '~/global/Contexts/Global.context';

export const Chat = observer(() => {
	const {
		liveChat = {},
		liveChat: {
			showChatIcon = false,
		} = {},
	} = useGlobalContext();

	function handleClick() {
		liveChat.openChat();
	}

	if (showChatIcon) {
		return (
			<button data-qa="mobile-chat-button" data-tr-link-event-comp-name="chat now" className="tw-font-semibold tw-text-left tw-w-full tw-py-1" onClick={handleClick}>
				<img src={`${s7ContentPath}/chat`} width="28" alt="" className="tw-mx-2" />
				Chat Now
			</button>
		);
	}
	return (
		<div className="tw-flex">
			<img src={`${s7ContentPath}/chat`} width="28" alt="" className="tw-mx-2" />
			<div >
				<p className="tw-font-semibold tw-m-0">Chat Now</p>
				<p className="tw-text-3xs tw-text-gray-200 tw-m-0">Currently not available</p>
			</div>
		</div>
	);
});
