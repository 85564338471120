import type { Dispatch, SetStateAction } from 'react';
import React from 'react';
import { observer } from 'mobx-react';
import cn from 'classnames';

import { s7ContentPath } from '~/global/global.constants';
import { DevBeamWrapper } from '~/layout/Components/toolbar/DevBeamWrapper';
import { useGlobalContext } from '~/global/Contexts/Global.context';
import listStyles from '#/mobile/global/menuList.module.scss';

type Props = {
	devBeamOpen: boolean
	setDevBeamOpen: Dispatch<SetStateAction<boolean>>
}
export const TopMenuMobileToolbar = observer(({ devBeamOpen, setDevBeamOpen }: Props) => {
	const { showDevBeam, featureTogglesModel } = useGlobalContext();

	return (
		<li suppressHydrationWarning className={`${listStyles['link-item-dev-beam']}`}>
			{
				showDevBeam &&
				<>
					<div className={cn('tw-bg-blue tw-w-full tw-relative', {
						'dev-beam-back': devBeamOpen
					})}>
						{
							featureTogglesModel.isOn('DEBUG_MODE') &&
							<div className="tw-absolute tw-bg-red tw-px-1 tw-text-white tw-font-bold tw-text-3xs tw-bottom-0 tw-right-0">Debug ON</div>
						}
						<button
							className={cn(
								listStyles['link-item-dev-beam-button'],
								'tw-grayscale tw-font-bold tw-text-white tw-no-underline tw-block',
								'tw-overflow-hidden tw-w-full tw-text-left',
							)}
							type="button"
							onClick={() => {
								setDevBeamOpen(!devBeamOpen);
							}}
						>
							{
								devBeamOpen
									?
									<>
										<img
											className="tw-mr-2 tw-brightness-0 tw-invert"
											src={`${s7ContentPath}/icon_backSecondarySubmenu_gray`}
											width="6"
											alt=""
										/>
										<span>Back</span>
									</>
									:
									<span>Tools</span>
							}
						</button>
					</div>
					{
						devBeamOpen &&
						<DevBeamWrapper mode="MOBILE" className="" />
					}
				</>
			}
		</li>
	);
});
