import React, { Suspense } from 'react';
import dynamic from 'next/dynamic';
import cn from 'classnames';

import { PreMenu } from '~/global/header/Components/PreMenu';
import { SearchInput } from '~/global/header/Components/SearchInput';
import { HeaderLogo } from '~/global/header/Components/HeaderLogo';
import { AccessibilityLinks } from '~/layout/Components/AccessibilityLinks';
import { isEngage } from '~/global/global.constants';
import AccountMenuPlaceholder from '~/account/top-links/Components/AccountMenu.placeholder';
import { StoresLink } from '~/account/top-links/Components/StoresLink';
import FavoritesLinkPlaceholder from '~/account/top-links/Components/FavoritesLink.placeholder';
import CartLinkPlaceholder from '~/account/top-links/Components/CartLink.placeholder';

const AccountMenu = dynamic(
	() => import('~/account/top-links/Components/AccountMenu'),
	{ ssr: false, loading: () => <AccountMenuPlaceholder /> },
);
const FavoritesLink = dynamic(
	() => import('~/account/top-links/Components/FavoritesLink'),
	{ ssr: false, loading: () => <FavoritesLinkPlaceholder /> },
);
const CartLink = dynamic(
	() => import('~/account/top-links/Components/CartLink'),
	{ ssr: false, loading: () => <CartLinkPlaceholder /> },
);

export const Header = () => {
	return (
		<header
			className={cn('header', {
				'tw-mt-5': isEngage,
				'print:tw-mt-0': isEngage,
			})}
			data-qa="header"
			data-tr-link-event-comp-type="header navigation"
		>
			<div className="tw-w-full">
				<AccessibilityLinks />
			</div>
			<div className="tw-flex tw-justify-between">
				<SearchInput />
				<HeaderLogo />
				<div
					className="top-links tw-w-[220px]"
					data-tr-link-event-comp-name="top links"
				>
					<StoresLink />
					<AccountMenu />
					<FavoritesLink />
					<CartLink className="top-link" />
				</div>
			</div>
			<PreMenu />
		</header>
	);
};
